import { useCallback, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { getTimeDiffJson } from '../utils/get-time-from-timestamp';
import { SocketStatus } from '../typings';
import { debounce } from '@mui/material';

const useSocket = (
  getSocket: () => Socket,
  onReconnect?: (lastUpdatedTimestamp: number) => void
) => {
  const [socket, setSocket] = useState(getSocket());
  const socketName = ((socket as any).nsp! ?? '').substring(1);
  const savedCurrentTimeRef = useRef<number>(new Date().getTime());

  const isConnectedRef = useRef(false);
  const lastUpdatedTimestampRef = useRef(new Date().getTime());
  const onReconnectRef = useRef(onReconnect);
  const BUFFER_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds prev msgs will be fetched
  // we will update on mouseMove and on Socket msg received
  const lastSocketMsgTimeRef = useRef(new Date().getTime());

  onReconnectRef.current = onReconnect;

  const addListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.on(eventName, listener);
    },
    [socket]
  );

  const removeListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.off(eventName, listener);
    },
    [socket]
  );

  useEffect(() => {
    // setSocket(getSocket());
    const newSocket = getSocket();
    if (newSocket !== socket) {
      setSocket(newSocket);
    }
  }, [getSocket, setSocket]);

  useEffect(() => {
    const updateLastMouseMoveTime = () => {
      lastSocketMsgTimeRef.current = new Date().getTime();
      console.log(
        'updating lastSocketMsgTimeRef time',
        lastSocketMsgTimeRef.current
      );
    };
    socket.on('message', updateLastMouseMoveTime);
    return () => {
      socket.off('message', updateLastMouseMoveTime);
    };
  }, []);

  useEffect(() => {
    const onConnect = () => {
      updateSocketStatus();
      isConnectedRef.current = true;
    };

    const onDisconnect = (reason: Socket.DisconnectReason) => {
      if (window.socketStatus) {
        window.socketStatus[socketName] = {
          status: SocketStatus.NOT_CONNECTED,
          message: reason || 'Unknown reason',
        };
      } else {
        window.socketStatus = {
          [socketName]: {
            status: SocketStatus.NOT_CONNECTED,
            message: reason || 'Unknown reason',
          },
        };
      }

      if (navigator.onLine) {
        socket.connect();
        return;
      }
      isConnectedRef.current = false;
      lastUpdatedTimestampRef.current = new Date().getTime();
      // socket.disconnect();
    };

    socket.connect();
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.disconnect();
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket]);

  const updateSocketStatus = () => {
    if (socket.connected) {
      if (window.socketStatus) {
        window.socketStatus[socketName] = {
          status: SocketStatus.CONNECTED,
          message: 'Connected successfully',
        };
      } else {
        window.socketStatus = {
          [socketName]: {
            status: SocketStatus.CONNECTED,
            message: 'Connected successfully',
          },
        };
      }
    }
  };
  useEffect(() => {
    updateSocketStatus();
  }, [socket.connected]);

  useEffect(() => {
    let threshold = 10000;
    let timeOut = 0;
    const refreshChat = (lastSyncTime?: number) => {
      const lastUpdatedTime = lastSyncTime || lastUpdatedTimestampRef.current;
      if (lastUpdatedTime) {
        const { elapsedHours } = getTimeDiffJson(lastUpdatedTime);

        if (elapsedHours > 6) {
          window.location.reload();
          return;
        }
      }

      socket.disconnect();
      socket.connect();
      onReconnectRef.current?.(lastUpdatedTime - BUFFER_THRESHOLD);
      lastUpdatedTimestampRef.current = new Date().getTime();
      // lastSocketMsgTimeRef.current = lastUpdatedTimestampRef.current;
    };

    const checkForSleep = debounce(() => {
      const savedCurrentTime = savedCurrentTimeRef.current;
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - savedCurrentTime;

      // Check if the difference is significantly more than 2 seconds
      if (timeDifference > threshold + 1000) {
        console.log(
          'System may have been asleep',
          new Date(currentTime),
          timeDifference
        );
        if (navigator.onLine) {
          refreshChat(lastSocketMsgTimeRef.current);
          lastSocketMsgTimeRef.current = currentTime - BUFFER_THRESHOLD;
        } else {
          console.log('system is offline');
        }
      }

      // Save the current time for the next check
      savedCurrentTimeRef.current = currentTime;
      // Schedule the next check
      clearTimeout(timeOut);
      timeOut = window.setTimeout(checkForSleep, threshold);
    }, 300);

    checkForSleep();
    return () => {
      clearTimeout(timeOut);
    };
  }, [socket]);

  useEffect(() => {
    const refreshChat = (event?: Event, lastSyncTime?: number) => {
      const lastUpdatedTime = lastSyncTime || lastUpdatedTimestampRef.current;
      if (lastUpdatedTime) {
        const { elapsedHours } = getTimeDiffJson(lastUpdatedTime);

        if (elapsedHours > 6) {
          window.location.reload();
          return;
        }
      }

      socket.disconnect();
      socket.connect();
      onReconnectRef.current?.(lastUpdatedTime);
      lastUpdatedTimestampRef.current = new Date().getTime();
      // lastSocketMsgTimeRef.current = lastUpdatedTimestampRef.current;
    };
    const reConnect = () => {
      if (isConnectedRef.current && socket.connected) {
        updateSocketStatus();
        return;
      }
      refreshChat();
    };

    const handleOnVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        reConnect();
      }
    };

    window.addEventListener('focus', reConnect);
    window.addEventListener('online', refreshChat);
    window.addEventListener('visibilitychange', handleOnVisibilityChange);

    return () => {
      window.removeEventListener('focus', reConnect);
      window.removeEventListener('online', refreshChat);
      window.removeEventListener('visibilitychange', handleOnVisibilityChange);
      // window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [socket, socket.connected, socketName]);

  return { socket, addListener, removeListener };
};

export default useSocket;
